

.root{
    border-radius: 6px;
    background: rgba(0, 0, 0, 0.15);
    padding: 16px;
    display: flex;
    align-items: center;
}

.root:not(:first-child){
    margin-top: 5px;
}

.action>div{
    font-family: SourceSansPro-SemiBold;
    font-size: 16px;
    font-weight: normal;
    color: #FFFFFF;
}

.action{
    width: calc(100% - 30% - 64px);
    padding: 0 2%;
}

.action>div{
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.action>button{
    background: #FFC46E;
    border: 0;
    padding: 6px 10px;
    border-radius: 5px;

    font-family: SourceSansPro-Semibold;
    font-size:1em;
    font-weight: 600;
    color: #3F3E3C;

    margin-top: 5px;
}

.play{
    padding:8px 2%;
    width: 28%;
    border-radius: 4px;
    opacity: 1;
    background: linear-gradient(106deg, #FFB200 0%, #FF7E00 100%);
    border: 0;
    height: 60%;

    font-family: SourceSansPro-Semibold;
    font-size: 1em;
    font-weight: 600;
    text-align: center;
    color: #FFFFFF;

    white-space:nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.img{
    border-radius: 10px;
    width: 64px;
}