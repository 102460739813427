.menus{
    height: 95vh;
    position: fixed;
    z-index: 2;
    background: #051A21;
    box-shadow: 10px 20px 10px 0px rgba(0, 0, 0, 0.35);
    width: 50vw;
    top:5vh;
}

.menus>*{
    font-family: SourceSansPro-Semibold;
    font-size: 14px;
    font-weight: 600;
    color: #FFFFFF;
    padding: 15px 0;
    padding-left: 20px;
    background-color: unset;
}

.menus>:first-child{
    margin-top: 30px;
}