.App{
    height: 100vh;
}
.APP>span{
    display: none;
}



  .navbar{
    height: 5vh;
    background: #0D262E;
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: fixed;
    top: 0;
    z-index: 1;
}

.category-root{
    position: relative;
    top:5vh;
    background: #051A21;
    padding: 1vh 20px;
    overflow-x: auto;
    display: flex;
    height: 3vh;
}

  .body{
    background: #005AA2;
    padding: 2vh 20px;
    overflow-y: auto;
    position: relative;
    top:5vh;
}

.footer{
    background-color: #0D262E;
    text-align: center;

    font-family: SourceSansPro-Regular;
    font-size: 14px;
    font-weight: normal;

    color: #FFFFFF;

    padding: 1vh 0;
    height: 8vh;
    
    position: relative;
    top: 5vh;

    z-index: 3;
   

}