.more-news-root{
    display: flex;
    flex-wrap: wrap;
}

.more-news-root>div:nth-child(3n+2){
    flex:1 1 auto;
}
.more-news-root>div:nth-child(3n+1),.more-news-root>div:nth-child(3n+3){
    flex:0 1 45%;
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.25);
    margin-bottom: 10px;
}

.more-news-root div>img{
    width: 100%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.more-news-root div>p{
    font-family: SourceSansPro-Regular;
    font-size: 18px;
    color: #FFFFFF;
    padding: 0;
    
    overflow : hidden;
    text-overflow: ellipsis;
    display: -webkit-box;

    -webkit-box-orient: vertical;
    padding-left: 10px;
}
.news-title{
    font-weight: 600;
    -webkit-line-clamp: 2;
}
.news-content{
    font-weight: normal;
    opacity: 0.5;
    -webkit-line-clamp: 4;
}