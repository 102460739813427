.news-root{
    border-radius: 6px;
    background: rgba(0, 0, 0, 0.15);
    padding: 16px;
    display: flex;
}

.news-root:not(:first-child){
    margin-top: 5px;
}
.news-root>:last-child{
   width: calc(100% - 64px  - 10px);
}
.new-content{
    padding-left: 10px;
}
.new-content>div{
    font-family: SourceSansPro-Semibold;
    font-size: 14px;
    font-weight: 600;
    color: #FFFFFF;
}
.new-content>p{
    font-family: SourceSansPro-Regular;
    font-size: 11px;
    font-weight: normal;
    color: #FFFFFF;
    opacity: 0.5;
}