

.category-item{
    border-radius: 5.5px;
    background: #045CA3;
   
    display: flex;
    padding: 5px 10px;
    align-items: center;
    justify-content: space-around;
       
    font-family: SourceSansPro-Semibold;
    font-size: 12px;
    font-weight: 600;
    color: #FFF;
}

.category-item:not(:first-child){
    margin-left: 15px;
}

.category-item>span{
    margin-left: 10px;
}

.category-item.active{
    color: #0D262E;
    background: #FEB101;
}

